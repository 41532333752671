.Category {
  margin-bottom: 20px;
  font-family: "Open Sans", sans-serif;
}

.Header {
  text-transform: uppercase;
  color: #666666;
  font-size: 14px;
}
