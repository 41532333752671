.DesktopNavLink {
  font-size: 14px;
  color: #444444;
  text-transform: uppercase;
  display: inline-block;
  font-weight: 700;
  margin-left: 30px;
  &:hover {
    cursor: pointer;
    color: #ff5561;
  }

  &:hover + .my-dropdown {
    display: block;
  }
}
