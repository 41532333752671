.webp {
  .Home {
    background-image: url("../../../../src/assets/pedders-suspension.webp");
  }
}

.no-webp {
  .Home {
    background-image: url("../../../../src/assets/pedders-suspension.jpg");
  }
}
