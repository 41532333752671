.CatalogueItemCard {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  min-height: 420px;
  border: 1px solid black;

  .dsc {
    max-width: 250px;
    text-align: center;
    color: #444444;
    font-family: "Open Sans", sans-serif;
    font-size: 14px;
  }
}
