.MiniBasket {
  position: absolute;
  z-index: 10;
  top: 120%;
  right: -15px;
  width: 300px;
  font-size: 14px;
  text-align: center;
  min-height: 100px;
  display: flex;
  background-color: #f2f2f2;
  box-shadow: 0 3px 3px rgba(0, 0, 0, 0.15);
}

.MiniBasketEmpty {
  padding: 10px;
  justify-content: center;
  align-items: center;
}

.MiniBasketFull {
  flex-direction: column;
}
