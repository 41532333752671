.NowShoppingBy {
  margin-bottom: 20px;
  font-family: "Open Sans", sans-serif;
  color: #666666;
  font-size: 12px;
}

.Button {
  display: inline-block;
  font-family: "Open Sans", sans-serif;
  font-size: 12px;
  padding: 5px 10px;
  text-transform: uppercase;
  background-color: #333;
  margin-top: 15px;
  color: #fff;
  outline: none;
  border: none;
}

.Header {
  text-transform: uppercase;
  margin-bottom: 10px;
  font-weight: bolder;
}

.Subheader {
  font-weight: bolder;
}
