.scrollContainer {
  background-color: red;
  color: white;
  font-family: "Nunito Sans", sans-serif;
  padding: 2px 0;
  font-weight: bold;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  box-sizing: border-box;
}
.scrollText {
  display: inline-block;
  animation: scroll 15s linear infinite;
  padding-left: 100%;

  @keyframes scroll {
    0% {
      transform: translate(0, 0);
    }
    100% {
      transform: translate(-100%, 0);
    }
  }
}
