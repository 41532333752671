a,
a:hover {
  text-decoration: none;
  color: black;
}

.popular {
  padding-top: 30px;
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  color: #666666;
  text-align: justify;
  line-height: 1.66;
}

html {
  scroll-behavior: smooth;
}
