$arrow-size: 35px;
$arrow-bg-color: rgb(255, 255, 255);
$arrow-box-shadow: rgba(0, 0, 0, 0.6) 0px 0px 5px 0px;
$arrow-opacity: 0.5;
$arrow-hover-opacity: 1.2;
$small-screen-width: 500px;

.CarouselWrapper {
  position: relative;
  z-index: 1;
}

.splide__slide {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.splide > div > div.splide__arrows.splide__arrows--ltr > button.splide__arrow {
  background-color: $arrow-bg-color;
  box-shadow: $arrow-box-shadow;
  opacity: $arrow-opacity;
  width: $arrow-size;
  height: $arrow-size;
  transition: opacity 0.25s ease;
}

.splide
  > div
  > div.splide__arrows.splide__arrows--ltr
  > button.splide__arrow:hover {
  opacity: $arrow-hover-opacity;
}

.splide
  > div
  > div.splide__arrows.splide__arrows--ltr
  > button.splide__arrow--prev {
  left: -1em;
}

.splide
  > div
  > div.splide__arrows.splide__arrows--ltr
  > button.splide__arrow--next {
  right: -1em;
}

@media (max-width: $small-screen-width) {
  .splide
    > div
    > div.splide__arrows.splide__arrows--ltr
    > button.splide__arrow--prev {
    left: 1em;
  }

  .splide
    > div
    > div.splide__arrows.splide__arrows--ltr
    > button.splide__arrow--next {
    right: 1em;
  }
}

.splid__track {
  height: 110px;
}

.divider {
  position: absolute;
  top: 10%;
  right: 0%;
  height: 80%;
  border-right: 1px solid #ddd;
}

.carousel {
  background-color: white;
  margin: 20px 0;
  border-radius: 6px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

  &__lines {
    height: 90px;
    border-radius: 6px;
    margin: 10px 0;
  }

  &__link {
    display: flex;
    justify-content: center;
  }

  &__text {
    margin-top: 8px;
  }
}
