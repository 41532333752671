.MobileNavLink {
  font-size: 14px;
  color: #444444;
  text-transform: uppercase;
  display: inline-block;
  font-weight: 700;
  &:hover {
    cursor: pointer;
    color: #ff5561;
  }
}
