.Image {
  object-fit: contain;
  max-width: 100%;
}

.Wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #ddd;
}

.Switcher {
  margin-top: 10px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 10px;

  img {
    border: 1px solid #ddd;
    width: 100%;
    height: 100px;
    object-fit: contain;

    &:hover {
      cursor: pointer;
    }
  }
}
