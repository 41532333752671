.custom-control-label {
  cursor: pointer !important;
}

.custom-switch .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #dc3545;
  border-color: #dc3545;
  cursor: pointer;
}

.custom-switch .custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
  cursor: pointer;
}

.custom-switch
  .custom-control-input:focus:not(:checked)
  ~ .custom-control-label::before {
  border-color: #f1aeb5;
  cursor: pointer;
}

.custom-switch
  .custom-control-input:disabled:checked
  ~ .custom-control-label::before {
  background-color: rgba(220, 53, 69, 0.9);
  border-color: rgba(220, 53, 69, 0.9);
  cursor: pointer;
}
